import React, { useState, useEffect} from "react"
import StyledHeader from "./styled/StyledHeader"
import {Carousel} from '@trendyol-js/react-carousel';
import styled from "styled-components"
const newsItems = [
    { id: 1, Messe: "PropTech Summit Hamburg", Wann: "Dienstag 9. April – Freitag 12. April 2024", Wo: "Australiastr. 52, 20457 Hamburg", Rolle: "Aussteller (Stand B1)", },
    { id: 2, Messe: "Real Estate Hannover", Wann: "Mittwoch 5. Juni 2024",Wo: "Messegelände, 30521 Hannover", Rolle: "Besucher", },
    //{ id: 3, Messe: "ZIA TDI Berlin", Wann: "Montag 10. Juni – Mittwoch 12. Juni 2024",Wo: "Möckernstr. 10, 10963 Berlin", Rolle: "Besucher", },
    { id: 4, Messe: "CAFM-Messe Fulda", Wann: "Montag 17. Juni – Dienstag 18. Juni 2024", Wo: "Esperantoplatz 1, 36037 Fulda", Rolle: "Austeller (Stand 5b)",  },
    { id: 5, Messe: "FeuerTrutz Nürnberg", Wann: "Dienstag 25. Juni – Freitag 28. Juni 2024",Wo: "Messezentrum 1, 90471 Nürnberg", Rolle: "Aussteller (Halle 4, Stand 224i)", },
    //{ id: 6, Messe: "EXPOReal München", Wann: "Sonntag 6. Oktober – Mittwoch 9. Oktober 2024",Wo: "Messegelände, 81823 München", Rolle: "Besucher", },
];
const StyledArrowButton = styled.div`
    width: 64px;
    height: 64px;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3b97d3;
    font-size: 24px;
    transition: all 0.3s;
    position: relative;
    top: calc(50% - 32px);
  
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`


export default function () {


        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }, []);
        return <StyledHeader>
            <div className="container">

                <div className="blur">
                    <h1>Wir können<br /><span>Facility Management</span>.</h1>
                    <h2>Persönlich. Digital. Bundesweit.</h2>
                    <div className="buttons">
                        <button onClick={() => {
                            const section = document.querySelector( '#faq-list' );
                            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                        }}><i className="fas fa-arrow-down" /> Mehr erfahren</button>
                        <button onClick={() => {
                            const section = document.querySelector( '#contact' );
                            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                        }}><i className="fas fa-phone" /> Kontakt aufbauen</button>
                    </div>
                </div>
            </div>
            <div className="triangle"></div>
            <div className="triangle2"></div>
           <div className="upper">
           <Carousel className="newsSlider" dynamic key={windowSize[0]} autoSwipe={2000} responsive={true} show={windowSize[0] >= 1280 ? 4 : (windowSize[0] >= 1024 ? 3 : (windowSize[0] >= 768 ? 2 : 1))} slide={1} swiping={true} leftArrow={<StyledArrowButton><i className="fas fa-arrow-left" /></StyledArrowButton>} rightArrow={<StyledArrowButton><i className="fas fa-arrow-right" /></StyledArrowButton>} infinite={true}>
               {newsItems.map(item => (
                     <div className="news-container" key={item.id} style={{paddingLeft:"12px"}} >
                        <div className="add">
                         <div style={{fontWeight:"bold"}}>{item.Messe}</div>
                         <div>{item.Rolle}</div>
                         <div>{item.Wann}</div>
                         <div>{item.Wo}</div>
                        </div>
                     </div>

                ))}
             </Carousel>
            </div>
            {/*<div style={{position:"absolute", bottom: "-50px", zIndex:"4",border:"1px", padding:"4px",borderRadius:"0 0 12px 12px",userSelect:"none", boxShadow: "0 10px 20px -5px rgba(0, 0, 0, .125)" }}>
                <h1>MESSEKALENDER 2024</h1>
            </div>*/}
        </StyledHeader>

}

